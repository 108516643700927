import React from 'react';

function Team() {
  return (
      <section className="py-24 bg-gradient-to-b from-gray-100 to-white border-t border-gray-200">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="py-12 md:py-20">

            {/* Section header */}
            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
              <h2 className="h2">The humans behind CityLite</h2>
              <p className="text-xl text-gray-600 dark:text-gray-400">A powerful team of problem-solvers, visionaries, innovators, engineers and researchers.</p>
            </div>

            {/* Team members */}
            <div className="max-w-sm sm:max-w-5xl mx-auto sm:flex sm:flex-wrap sm:justify-center -my-6 sm:-my-8 sm:-mx-3" data-aos-id-team>

              {/* 1st member */}
              <div className="sm:w-1/2 md:w-1/3 py-6 sm:py-8 sm:px-3" data-aos="zoom-y-out" data-aos-anchor="[data-aos-id-team]">
                <div className="flex flex-col items-center">
                  <img className="rounded-full mb-4" src={'/images/team-member-01-color.jpg'} width="115" height="115" alt="Team member 01" />
                  <h4 className="text-xl font-bold mb-1">Marios Messios</h4>
                  <div className="text-branded1-100 font-medium mb-2">CEO & Co-founder</div>
                  <p className="text-gray-600 text-center mb-3">Marios is an architect at heart with a vision to optimise and organise architectural design and improve our built environment.</p>
                  <p className="text-gray-600 text-center mb-3">#smartcities</p>
                  <div className="text-sm text-gray-600 font-medium">
                    <a className="text-gray-900 hover:underline" href="https://twitter.com/messiosmarios" target="_blank" rel="noopener noreferrer">Twitter</a> · <a className="text-gray-900 hover:underline" href="https://www.linkedin.com/in/messiosmarios" target="_blank" rel="noopener noreferrer">LinkedIn</a> · <a className="text-gray-900 hover:underline" href="https://github.com/messiosmarios" target="_blank" rel="noopener noreferrer">GitHub</a>
                  </div>
                </div>
              </div>

              {/* 2nd member */}
              <div className="sm:w-1/2 md:w-1/3 py-6 sm:py-8 sm:px-3" data-aos="zoom-y-out" data-aos-delay="150" data-aos-anchor="[data-aos-id-team]">
                <div className="flex flex-col items-center">
                  <img className="rounded-full mb-4" src={'/images/team-member-02-color.jpg'} width="115" height="115" alt="Team member 02" />
                  <h4 className="text-xl font-bold mb-1">Anninos Loizou</h4>
                  <div className="text-branded1-100 font-medium mb-2">COO & Co-founder</div>
                  <p className="text-gray-600 text-center mb-3">Anninos handles the daily operations of the company, including projects and budgeting, financial, projections and contributes on marketing and sales strategies.</p>
                  <p className="text-gray-600 text-center mb-3">#scrummaster</p>
                  <div className="text-sm text-gray-600 font-medium">
                    <a className="text-gray-900 hover:underline" href="https://twitter.com/anninosloizou" target="_blank" rel="noopener noreferrer">Twitter</a> · <a className="text-gray-900 hover:underline" href="https://www.linkedin.com/in/anninos-loizou" target="_blank" rel="noopener noreferrer">LinkedIn</a> · <a className="text-gray-900 hover:underline" href="https://github.com/Al630" target="_blank" rel="noopener noreferrer">GitHub</a>
                  </div>
                </div>
              </div>

              {/* 3rd member */}
              <div className="sm:w-1/2 md:w-1/3 py-6 sm:py-8 sm:px-3" data-aos="zoom-y-out" data-aos-delay="300" data-aos-anchor="[data-aos-id-team]">
                <div className="flex flex-col items-center">
                  <img className="rounded-full mb-4" src={'/images/team-member-03-color.png'} width="115" height="115" alt="Team member 03" />
                  <h4 className="text-xl font-bold mb-1">Harry Collin</h4>
                  <div className="text-branded1-100 font-medium mb-2">Lead Software Developer</div>
                  <p className="text-gray-600 text-center mb-3">Harry is the lead software developer of Parametricos Ltd. Harry has more than 4 years experience in developing 3D applications on the web.</p>
                  <p className="text-gray-600 text-center mb-3">#codeninja</p>
                  <div className="text-sm text-gray-600 font-medium">
                    <a className="text-gray-900 hover:underline" href="https://twitter.com/HHammerite" target="_blank" rel="noopener noreferrer">Twitter</a> · <a className="text-gray-900 hover:underline" href="https://www.linkedin.com/in/harrycollin/" target="_blank" rel="noopener noreferrer">LinkedIn</a> · <a className="text-gray-900 hover:underline" href="https://github.com/harrycollin" target="_blank" rel="noopener noreferrer">GitHub</a>
                  </div>
                </div>
              </div>

              {/* 4th member */}
              <div className="sm:w-1/2 md:w-1/3 py-6 sm:py-8 sm:px-3" data-aos="zoom-y-out" data-aos-delay="450" data-aos-anchor="[data-aos-id-team]">
                <div className="flex flex-col items-center">
                  <img className="rounded-full mb-4" src={'/images/team-member-04-color.png'} width="115" height="115" alt="Team member 04" />
                  <h4 className="text-xl font-bold mb-1">Martha Matsi</h4>
                  <div className="text-branded1-100 font-medium mb-2">Lead Research & Development</div>
                  <p className="text-gray-600 text-center mb-3">Martha researches the BIM and GIS industry, scouting for updates and trends in all aspects and through her experience in financial analysis.</p>
                  <p className="text-gray-600 text-center mb-3">#research</p>
                  <div className="text-sm text-gray-600 font-medium">
                    <a className="text-gray-900 hover:underline" href="https://twitter.com/marthamatsi" target="_blank" rel="noopener noreferrer">Twitter</a> · <a className="text-gray-900 hover:underline" href="https://www.linkedin.com/in/marthamatsi/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}

export default Team;