import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Transition from '../utils/Transition.js';

function Header() {

    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const [top, setTop] = useState(true);

    const mobileNav = useRef(null);

    // close the mobile menu on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!mobileNavOpen || mobileNav.current.contains(target)) return;
            setMobileNavOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close the mobile menu if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!mobileNavOpen || keyCode !== 27) return;
            setMobileNavOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    // detect whether user has scrolled the page down by 10px
    useEffect(() => {
        const scrollHandler = () => {
            window.pageYOffset > 10 ? setTop(false) : setTop(true)
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);

    // Handle light modes
    const [darkMode, setDarkMode] = useState(() => {
        const dark = localStorage.getItem('dark-mode');
        if (dark === null) {
            return false;
        } else {
            return dark === 'true';
        }
    });

    useEffect(() => {
        localStorage.setItem('dark-mode', darkMode)
        if (darkMode) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }, [darkMode]);

    return (
        <header className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${!top && 'bg-white dark:bg-gray-900 blur shadow-lg'}`}>
            <div className="max-w-6xl mx-auto px-5 sm:px-6">
                <div className="flex items-center justify-between h-20">

                    {/* Site branding */}
                    <div className="flex-shrink-0 mr-4">
                        {/* Logo */}
                        <Link to="/" className="block" aria-label="Cruip">
                            <div className="flex flex-row items-center">
                                <div>
                                    <img className="relative max-w-full mx-auto md:mr-0 md:max-w-none h-auto pointer-events-none" src={require('../images/citylite-logo.png')} width="35" height="35" alt="citylite logo" aria-hidden="true" />
                                </div>
                                    <div className="text-gray-900 dark:text-gray-200 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center text-xl font-bold">CityLite</div>
                            </div>

                            {/*<svg className="w-8 h-8" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*    <defs>*/}
                            {/*        <radialGradient cx="21.152%" cy="86.063%" fx="21.152%" fy="86.063%" r="79.941%" id="header-logo">*/}
                            {/*            <stop stopColor="#4FD1C5" offset="0%" />*/}
                            {/*            <stop stopColor="#81E6D9" offset="25.871%" />*/}
                            {/*            <stop stopColor="#338CF5" offset="100%" />*/}
                            {/*        </radialGradient>*/}
                            {/*    </defs>*/}
                            {/*    <rect width="32" height="32" rx="16" fill="url(#header-logo)" fillRule="nonzero" />*/}
                            {/*</svg>*/}
                        </Link>
                    </div>

                    {/* Desktop navigation */}
                    <nav className="hidden md:flex md:flex-grow">

                        {/* Desktop menu links */}
                        <ul className="flex flex-grow justify-end flex-wrap items-center">
                            <li>
                                <Link to="/" className="text-gray-900 dark:text-gray-200 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Home</Link>
                            </li>
                            <li>
                                <Link to="/about" className="text-gray-900 dark:text-gray-200 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">About us</Link>
                            </li>
                        </ul>

                        {/* Desktop lights switch */}
                        <div className="form-switch focus-within:outline-branded1-200 flex flex-col justify-center ml-3">
                            <input type="checkbox" name="light-switch" id="light-switch-desktop" className="light-switch sr-only" checked={darkMode} onChange={() => setDarkMode(!darkMode)} />
                            <label className="relative" htmlFor="light-switch-desktop">
                                <span className="relative bg-gradient-to-t from-gray-100 to-white dark:from-gray-800 dark:to-gray-700 shadow-sm z-10" aria-hidden="true"></span>
                                <svg className="absolute inset-0" width="44" height="24" viewBox="0 0 44 24" xmlns="http://www.w3.org/2000/svg">
                                    <g className="fill-current text-white" fillRule="nonzero" opacity=".88">
                                        <path d="M32 8a.5.5 0 00.5-.5v-1a.5.5 0 10-1 0v1a.5.5 0 00.5.5zM35.182 9.318a.5.5 0 00.354-.147l.707-.707a.5.5 0 00-.707-.707l-.707.707a.5.5 0 00.353.854zM37.5 11.5h-1a.5.5 0 100 1h1a.5.5 0 100-1zM35.536 14.829a.5.5 0 00-.707.707l.707.707a.5.5 0 00.707-.707l-.707-.707zM32 16a.5.5 0 00-.5.5v1a.5.5 0 101 0v-1a.5.5 0 00-.5-.5zM28.464 14.829l-.707.707a.5.5 0 00.707.707l.707-.707a.5.5 0 00-.707-.707zM28 12a.5.5 0 00-.5-.5h-1a.5.5 0 100 1h1a.5.5 0 00.5-.5zM28.464 9.171a.5.5 0 00.707-.707l-.707-.707a.5.5 0 00-.707.707l.707.707z" />
                                        <circle cx="32" cy="12" r="3" />
                                        <circle fillOpacity=".4" cx="12" cy="12" r="6" />
                                        <circle fillOpacity=".88" cx="12" cy="12" r="3" />
                                    </g>
                                </svg>
                                <span className="sr-only">Switch to light / dark version</span>
                            </label>
                        </div>

                        {/* Desktop sign in links */}
                        <ul className="flex flex-grow justify-end flex-wrap items-center">
                            <li>
                                <a href="https://github.com/Parametricos/CityLite" target="_blank" rel="noopener noreferrer" className="font-medium text-gray-900 dark:text-gray-200 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Github</a>
                            </li>
                            <li>
                                <a href="https://app.citylite.io" target="_blank" rel="noopener noreferrer" className="btn-sm text-white bg-branded1-200 hover:bg-branded1-100 ml-3">
                                    <span>Demo</span>
                                    <svg className="w-3 h-3 fill-current text-gray-200 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                                    </svg>
                                </a>
                            </li>
                        </ul>

                    </nav>

                    {/* Mobile menu */}
                    <div className="flex md:hidden">

                        {/* Hamburger button */}
                        <button
                            className={`hamburger ${mobileNavOpen && 'active'}`}
                            aria-controls="mobile-nav"
                            aria-expanded={mobileNavOpen}
                            onClick={() => setMobileNavOpen(!mobileNavOpen)}
                        >
                            <span className="sr-only">Menu</span>
                            <svg className="w-6 h-6 fill-current text-gray-900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <rect y="4" width="24" height="2" />
                                <rect y="11" width="24" height="2" />
                                <rect y="18" width="24" height="2" />
                            </svg>
                        </button>

                        {/*Mobile navigation */}
                        <div ref={mobileNav}>
                            <Transition
                                show={mobileNavOpen}
                                tag="nav"
                                id="mobile-nav"
                                className="absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll bg-white transition-all duration-300 ease-in-out"
                                enter="transition ease-out duration-200 transform"
                                enterStart="opacity-0 -translate-y-2"
                                enterEnd="opacity-100 translate-y-0"
                                leave="transition ease-out duration-200"
                                leaveStart="opacity-100"
                                leaveEnd="opacity-0"
                            >
                                <ul className="px-5 py-2">
                                    <li>
                                        <Link to="/" className="flex text-gray-600 hover:text-gray-900 py-2">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/about" className="flex text-gray-600 hover:text-gray-900 py-2">About us</Link>
                                    </li>
                                    <li>
                                        <a href="https://parametricos-cassini-hackathon.vercel.app/" target="_blank" rel="noopener noreferrer" className="flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center">Sign in</a>
                                    </li>
                                    <li>
                                        <a href="https://parametricos-cassini-hackathon.vercel.app/" target="_blank" rel="noopener noreferrer" className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 w-full my-2">
                                            <span>Sign up</span>
                                            <svg className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill="#999" fillRule="nonzero" />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </Transition>
                        </div>

                    </div>

                </div>
            </div>
        </header>
    );
}

export default Header;
