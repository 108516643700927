import React from 'react';

import HeaderSimple from "../partials/HeaderSimple";
import PageIllustration from '../partials/PageIllustration';
import HeroHome from '../partials/HeroHome';
import PricingTables from '../partials/PricingTables';
import TestimonialsBlocks from '../partials/TestimonialsBlocks';
import Footer from '../partials/Footer';
import TabsOpenPro from "../partials/TabsOpenPro";
import CtaContact from "../partials/CtaContact";
import FeaturesBlocks from "../partials/FeaturesBlocks";
import Carousel from "../partials/Carousel";

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <HeaderSimple />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1" aria-hidden="true">
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <HeroHome />
          <Carousel/>

          {/*<TabsOpenPro />*/}
        {/*<PricingTables />*/}
        {/*<TestimonialsBlocks />*/}
        {/*  <FeaturesBlocks/>*/}
        {/*<CtaContact />*/}

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Home;