import React, { useState } from 'react';
import Modal from '../utils/Modal';

function HeroHome() {

  const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <section className='min-h-screen flex flex-col items-center justify-center'>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 ">
        <div className="pt-32 pb-10 md:pt-40 md:pb-16">

          {/* Hero content */}
          <div className="md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center">

            {/* Content */}
            <div className="md:col-span-6 lg:col-span-6 mb-8 md:mb-0 text-center">
              <h1 className="h1 lg:text-6xl mb-4 font-red-hat-display font-extrabold" data-aos="fade-down">CityLite</h1>
              <p className="text-xl text-gray-600 dark:text-gray-400" data-aos="fade-down" data-aos-delay="150">CityLite is an award-winning open-source smart cities concept for professionals to simulate their BIM and GIS proposals</p>

              {/* CTA form */}
              <br/>
              <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center" data-aos="zoom-y-out" data-aos-delay="300">
                <div>
                  <a className="btn text-white bg-branded1-200 hover:bg-branded1-100 w-full mb-4 sm:w-auto sm:mb-0" href="https://app.citylite.io/" target="_blank" rel="noopener noreferrer">Try now</a>
                </div>
                <div>
                  <a className="btn text-white bg-gray-600 hover:bg-gray-800 w-full sm:w-auto sm:ml-4" href="https://superpeer.com/messiosmarios" target="_blank" rel="noopener noreferrer">Book a Demo</a>
                </div>
              </div>
              {/*<form className="mt-8" data-aos="fade-down" data-aos-delay="300">*/}
              {/*  <div className="flex flex-col sm:flex-row justify-center max-w-sm mx-auto sm:max-w-md md:mx-0">*/}
              {/*    <input type="tel" className="form-input w-full mb-2 sm:mb-0 sm:mr-2" placeholder="Phone number" aria-label="Phone number" />*/}
              {/*    <a className="btn text-white bg-teal-500 hover:bg-teal-400 flex-shrink-0" href="#0">Request code</a>*/}
              {/*  </div>*/}
              {/*  /!* Success message *!/*/}
              {/*  /!* <p className="text-center md:text-left mt-2 opacity-75 text-sm">Thanks for subscribing!</p> *!/*/}
              {/*</form>*/}
            </div>

            {/* Mobile mockup */}
            <div className="md:col-span-6 lg:col-span-6 text-center md:text-right" data-aos="fade-up" data-aos-delay="450">
              <div className="inline-flex relative justify-center items-center">
                {/*/!* Glow illustration *!/*/}
                {/*<svg className="absolute mr-12 mt-32 pointer-events-none -z-1 dark:opacity-40" aria-hidden="true" width="678" height="634" viewBox="0 0 678 634" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*  <circle cx="240" cy="394" r="240" fill="url(#piphoneill_paint0_radial)" fillOpacity=".4" />*/}
                {/*  <circle cx="438" cy="240" r="240" fill="url(#piphoneill_paint1_radial)" fillOpacity=".6" />*/}
                {/*  <defs>*/}
                {/*    <radialGradient id="piphoneill_paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(90 -77 317) scale(189.054)">*/}
                {/*      <stop stopColor="#667EEA" />*/}
                {/*      <stop offset="1" stopColor="#667EEA" stopOpacity=".01" />*/}
                {/*    </radialGradient>*/}
                {/*    <radialGradient id="piphoneill_paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(90 99 339) scale(189.054)">*/}
                {/*      <stop stopColor="#9F7AEA" />*/}
                {/*      <stop offset="1" stopColor="#9F7AEA" stopOpacity=".01" />*/}
                {/*    </radialGradient>*/}
                {/*  </defs>*/}
                {/*</svg>*/}
                {/*/!* Image inside mockup size: 290x624px (or 580x1248px for Retina devices) *!/*/}
                {/*<img className="absolute" src={require('../images/mockup-image-01.jpg')} width="290" height="624" style={{ maxWidth: '84.33%' }} alt="Features illustration" />*/}
                {/* iPhone mockup */}
                <img className="relative max-w-full mx-auto md:mr-0 md:max-w-none h-auto pointer-events-none w-full" src='/hero-landing-split.png' width="400" height="400" alt="iPhone mockup" aria-hidden="true" />
                {/*/!* Play button *!/*/}
                {/*<a className="absolute group" href="#0" onClick={(e) => { e.preventDefault(); setVideoModalOpen(true); }} aria-controls="modal">*/}
                {/*  <img src={require('../images/play-button.svg')} width="96" height="96" alt="Play" />*/}
                {/*</a>*/}
              </div>

              {/*/!* Modal *!/*/}
              {/*<Modal id="modal" ariaLabel="modal-headline" show={videoModalOpen} handleClose={() => setVideoModalOpen(false)}>*/}
              {/*  <div className="relative pb-9/16">*/}
              {/*    <iframe className="absolute w-full h-full" src="https://player.vimeo.com/video/174002812" title="Video" allowFullScreen></iframe>*/}
              {/*  </div>*/}
              {/*</Modal>*/}

            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default HeroHome;