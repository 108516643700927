import React from 'react';

import CtaContact from '../partials/CtaContact';
import Footer from '../partials/Footer';
import HeaderSimple from "../partials/HeaderSimple";
import AboutIntroSimple from '../partials/AboutIntroSimple'
import AboutStorySimple from '../partials/AboutStorySimple';
import Stats from "../partials/Stats";
import TeamSimple from "../partials/TeamSimple";
import Team from "../partials/Team";
import Timeline from "../partials/Timeline";
import AboutIntro from "../partials/AboutIntro";
import AboutStory from "../partials/AboutStory";


function About() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <HeaderSimple />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        {/*  <AboutIntro />*/}
          {/*<AboutStory />*/}
          <Team />
          {/*<Timeline />*/}
          {/*<CtaContact />*/}

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default About;